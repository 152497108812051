<template>
  <div class="review-out-box" v-if="showId == 'review'">
    <div class="review-plant">
      <div class="plant-title">种植信息</div>
      <div class="plant-main">
        <img
          class="plant-imgs"
          :src="
            infoDetail.photo
              ? uploadImgs + infoDetail.photo
              : require('../assets/image/crops/item1.jpg')
          "
          alt=""
        />
        <div class="plant-row">
          <span class="plant-label">
            品&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;种:
          </span>
          <span class="plant-content one-txt-cut">{{
            infoDetail.product_name || "无"
          }}</span>
        </div>
        <div class="plant-row">
          <span class="plant-label">前茬种植:</span>
          <span class="plant-content one-txt-cut">{{
            infoDetail.cname || "无"
          }}</span>
        </div>
        <div class="plant-row">
          <span class="plant-label">种植周期:</span>
          <span class="plant-content one-txt-cut"
            >{{ infoDetail.start_time }}—{{ infoDetail.end_time }}</span
          >
        </div>
      </div>
    </div>
    <div class="review-traceability">
      <div class="traceability-title">溯源信息</div>
      <div class="traceability-main">
        <div class="traceability-farm">
          <div class="farm-title">地块</div>
          <div class="farm-name one-txt-cut">{{ plotForm.title || "无" }}</div>
        </div>
        <div class="qrCode-box">
          <el-image class="default-imgs" v-if="!sn">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <vue-qr
            v-else
            class="qrcode-imgs"
            :text="
              'https://hs.darsing.net/trace/track.html?SN=' +
              sn +
              '&device_id=' +
              plotForm.device_id +
              '&position=&location='
            "
            :size="size"
          />
          <div class="other-info">
            <div class="info-row">
              <span class="big">{{ plotForm.area || 0 }}</span>
              <span>亩</span>
            </div>
            <div class="info-row">
              <span>承包人：</span>
              <span>{{ plotForm.desc || "无" }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getHanshanPlantingInfoApi,
  getHanshanTranceInfoApi,
} from "@/request/api.js";
import VueQr from "vue-qr";
import { mapState } from "vuex";

export default {
  name: "reviewPlant",
  components: {
    VueQr,
  },
  props: {
    showId: {
      type: String,
      default: "",
    },
    plotId: {
      type: [String, Number],
      default: 0,
    },
    plotForm: {
      type: Object,
      default: () => {
        return {
          company_name: "",
          phone: "",
          area: "",
          photo: "",
          title: "",
          desc: "",
          product_list: [],
        };
      },
    },
  },
  data() {
    return {
      infoDetail: {},
      sn: "",
      size: 600,
    };
  },
  watch: {
    plotId() {
      this.getHanshanTranceInfo();
      this.getHanshanPlantingInfo();
    },
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      uploadImgs: (state) => state.app.uploadImgs,
    }),
  },
  methods: {
    async getHanshanTranceInfo() {
      const { code, results } = await getHanshanTranceInfoApi({
        plot_id: this.plotId,
      });
      if (code !== 0) return;
      this.sn = results.sn;
    },
    async getHanshanPlantingInfo() {
      const { code, results } = await getHanshanPlantingInfoApi({
        plot_id: this.plotId,
      });
      if (code !== 0) return;
      this.infoDetail = results;
      this.infoDetail.start_time = this.infoDetail.start_time.slice(0, 7);
      this.infoDetail.end_time = this.infoDetail.end_time.slice(0, 7);
    },
  },
};
</script>

<style lang="less" scoped>
.review-out-box {
  width: 315px;
  position: absolute;
  top: 106px;
  left: 78px;
  z-index: 1999;
  display: flex;
  flex-direction: column;
  .review-plant {
    // height: 408px;
    width: 100%;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    background-color: #0f243d;
    display: flex;
    flex-direction: column;
    transition: all 0.5s;
    margin-bottom: 30px;
    .plant-title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: #fff;
      background-color: rgba(21, 92, 165, 0.9);
      padding: 0 10px;
      border-radius: 5px 5px 0px 0px;
    }
    .plant-main {
      width: 100%;
      // height: calc(100% - 30px);
      height: 260px;
      padding: 22px 0 10px 0;
      font-size: 14px;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      .plant-imgs {
        width: 202px;
        // height: 116px;
        // margin-bottom: 18px;
      }
      .plant-row {
        width: 212px;
        // height: calc(100% - 282px);
        font-size: 14px;
        margin-bottom: 12px;
        font-weight: 400;
        display: flex;
        align-items: center;
        .plant-label {
          color: #409eff;
          margin-right: 4px;
        }
        .plant-content {
          display: block;
          width: 60%;
          color: #fff;
        }
      }
    }
  }
  .review-traceability {
    // height: 408px;
    width: 100%;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    background-color: #0f243d;
    display: flex;
    flex-direction: column;
    transition: all 0.5s;
    // position: absolute;
    // top: 426px;
    // left: 78px;
    // z-index: 1999;
    .traceability-title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: #fff;
      background-color: rgba(21, 92, 165, 0.9);
      padding: 0 10px;
      border-radius: 5px 5px 0px 0px;
    }
    .traceability-main {
      width: 100%;
      // height: calc(100% - 30px);

      height: 189px;
      padding: 20px 30px;
      font-size: 14px;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      .traceability-farm {
        width: 100%;
        display: flex;
        font-size: 14px;
        font-weight: 400;
        color: #fff;
        margin-bottom: 22px;
        .farm-title {
          width: 58px;
          height: 23px;
          line-height: 23px;
          text-align: center;
          background: #155ca5;
          border-radius: 12px 0px 0px 12px;
          margin-right: 3px;
        }
        .farm-name {
          width: 194px;
          height: 23px;
          background: #11355c;
          line-height: 23px;
          text-align: center;
          border-radius: 0px 12px 12px 0px;
          padding: 0 6px;
        }
      }
      .qrCode-box {
        display: flex;
        width: 100%;
        align-items: center;
        .qrcode-imgs {
          width: 98px;
          height: 98px;
          margin-right: 38px;
        }
        /deep/.default-imgs {
          width: 98px;
          height: 98px;
          line-height: 98px;
          margin-right: 38px;
          text-align: center;
          background-color: rgb(245, 247, 250);
          .el-icon-picture-outline {
            font-size: 36px;
            color: rgb(144, 147, 153);
            line-height: -1 !important;
          }
        }
        .other-info {
          flex: 1;
          .info-row {
            span {
              color: #fff;
              font-size: 14px;
              font-weight: 400;
              &.big {
                font-size: 24px;
              }
              &:first-of-type {
                color: #409eff;
              }
            }
          }
        }
      }
    }
  }
}
</style>
